import React from 'react'
import { useParams } from '@tanstack/react-router'

import Icon from 'components/Icon'

import t from 'i18n'

import {
  nextStep
} from 'features/route/routeSlice'

import requestFullscreen from 'util/requestFullscreen'
import speak from 'util/voice'

import styles from './Controls.module.sass'

export function Controls ({ route, dispatch }) {
  const { direction } = useParams({ strict: true })

  const flooredIndex = Math.floor(route.index)

  const enRoute = flooredIndex !== route.index
  const orderedStops = direction === 'backward' ? [...route.stops].reverse() : route.stops

  const nextStop = orderedStops[flooredIndex + 1]
  const isFinalStop = !orderedStops[flooredIndex + 2]
  let zoneMessage
  let signalMessage

  if (nextStop && enRoute) {
    if (nextStop.signal) {
      signalMessage = t('signalMessage')
    }

    if (orderedStops[flooredIndex] && orderedStops[flooredIndex].zone !== nextStop.zone) {
      zoneMessage = t('zoneMessage')
    }
  }

  const onNextStepClick = () => {
    if (!nextStop) return

    const messageParts = []

    if (enRoute) {
      messageParts.push(nextStop.name)
    } else {
      messageParts.push(t('nextStop', { stopName: nextStop.name }))
    }

    if (nextStop.signal) {
      messageParts.push(t('signalStop'))
    }

    if (enRoute && isFinalStop) {
      messageParts.push(t('finalStop'))
    }

    speak(messageParts.join('. '))

    dispatch(nextStep())
  }

  return (
    <aside className={styles.wrap}>
      {zoneMessage
        ? (
          <div className={styles.control} onClick={() => { requestFullscreen(); speak(zoneMessage) }}>
            <Icon height={2} name='circle-info' />
          </div>
          )
        : null}

      {signalMessage
        ? (
          <div className={styles.control} onClick={() => { requestFullscreen(); speak(signalMessage) }}>
            <Icon height={2} name='bell' />
          </div>
          )
        : null}

      <div className={styles.control} onClick={() => { requestFullscreen(); onNextStepClick() }}>
        {enRoute
          ? (
            <Icon height={1.5} name='shop' />
            )
          : (
            <Icon height={1.75} name='bus-simple' />
            )}
      </div>
    </aside>
  )
}

export default Controls
