import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from '@tanstack/react-router'
import t from 'i18n'

import exitFullscreen from 'util/exitFullscreen'

import { selectRoutes } from './routesSlice'

import RouteTile from './RouteTile'
import TypeNavigation from './TypeNavigation'

export function Routes () {
  exitFullscreen()

  const routes = useSelector(selectRoutes)

  const { routesType } = useParams({ strict: true })

  return (
    <>
      <h1 className='m-0 text-center'>{t('shotoush')}</h1>

      <p className='text-center m-0'>{t('selectRouteNumber')}</p>

      <TypeNavigation routes={routes} />

      <div className='d-flex flex-wrap justify-content-center gap-3'>
        {routes.routes.map((route) => route.type === routesType
          ? (
            <RouteTile
              key={route.name}
              route={route}
              pathBase={`/type/${routesType}`}
            />
            )
          : null)}
      </div>
    </>
  )
}

export default Routes
