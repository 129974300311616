import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetJson } from '../../util/api'

const initialState = {
  status: 'initial',
  routes: []
}

export const fetchRoutes = createAsyncThunk(
  'routes/fetchRoutes',
  async () => {
    const response = await apiGetJson('routes')
    // The value we return becomes the `fulfilled` action payload
    return response.data
  }
)

export const routesSlice = createSlice({
  name: 'routes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoutes.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchRoutes.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.routes = action.payload.routes
      })
  }
})

// export const {} = routesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRoutes = (state) => state.routes

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectRoutes(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default routesSlice.reducer
