import React from 'react';

import icons from './icons.json';
import styles from './Icon.module.sass';

export function Icon ({ name, height, regularHeight, faded }) {
  const icon = icons[name]
  const style = {}

  if (regularHeight) {
    style.height = '0.8em'
    style.width = '0.8em'
    style.position = 'relative'
    style.top = '-0.1em'
  } else {
    style.height = `${(height || 1) * 0.07}em`
    style.width = `${(((height || 1) * 0.07) * icon.width) / icon.height}em`
  }

  if (faded) {
    style.opacity = 0.5
  }

  return (
    <svg
      viewBox={`0 0 ${icon.width} ${icon.height}`}
      className={styles.svg}
      style={style}
    >
      <use xlinkHref={`/shotoush_icons_sprite.svg#${name}`} />
    </svg>
  );
}

export default Icon
