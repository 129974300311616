const REPLACEMENTS = [
  [/rozc\./ig, 'rozcestí'],
  [/křiž\./ig, 'křižovatka'],
  [/nám\./ig, 'náměstí'],
  [/Vlt\./ig, 'Vltavou'],
  [/Jiz\./ig, 'Jizerou'],
  [/n\./ig, 'nad'],
  [/stř\./ig, 'středisko'],
  [/žel\./ig, 'železniční'],
  [/aut\.st\./ig, 'autobusové stanoviště'],
  [/aut\./ig, 'autobusová'],
  [/st\./ig, 'stanice'],
  [/zas\./ig, 'zastávka'],
  [/zdrav\./ig, 'zdravotní'],
  [/gymnasium/ig, 'gymnázium'],
  [/Scania-Label/ig, 'skányja lejbl'],
  [/Protherm/ig, 'proterm'],
  [/\bU\b/ig, 'u'],
  [/\bV\b/ig, 'v'],
  [/\bK\b/ig, 'k'],
]

export default function speak (string) {
  let replacedString = string

  REPLACEMENTS.forEach(replacement => {
    replacedString = replacedString.replace(replacement[0], replacement[1])
  })

  const utterance = new window.SpeechSynthesisUtterance(replacedString)
  utterance.lang = "cs"

  window.speechSynthesis.cancel()
  window.speechSynthesis.speak(utterance)
}
