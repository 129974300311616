import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import { useParams, Outlet } from '@tanstack/react-router'

import t from 'i18n'

import {
  fetchRoute,
  selectRoute
} from 'features/route/routeSlice'

import styles from './Route.module.sass'

export function Route () {
  const { routeSlug } = useParams({ strict: true })

  const route = useSelector(selectRoute)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRoute({ routeSlug }))
  }, [dispatch, routeSlug])

  if (route.status === 'loaded') return <Outlet />

  return (
    <div className={styles.loaderWrap}>
      <Spinner />
      {t('loadingRoute')}
    </div>
  )
}

export default Route
