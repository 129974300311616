import { configureStore } from '@reduxjs/toolkit';
import routesReducer from '../features/routes/routesSlice';
import routeReducer from '../features/route/routeSlice';

export const store = configureStore({
  reducer: {
    routes: routesReducer,
    route: routeReducer,
  },
});
