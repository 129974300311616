import React from 'react'

import Icon from 'components/Icon'
import styles from './Stop.module.sass'

export function Stop ({ stop }) {
  return (
    <div className={styles.wrap}>
      <div className={styles.zoneWrap}>
        <div className={styles.zone}>
          {stop.zone}
        </div>
      </div>

      <div className={styles.labelAndIcon}>
        <div className={styles.label}>
          {stop.name}
        </div>

        {stop.signal ? <Icon name='bell' /> : null}
      </div>
    </div>
  )
}

export default Stop
