import React, { useState, useEffect } from 'react'

import styles from './Time.module.sass'

const padNumber = (num) => num < 10 ? `0${num}` : num

export function Time () {
  const date = new Date()
  const [ctime, setTime] = useState(`${padNumber(date.getHours())}:${padNumber(date.getMinutes())}`)

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      const innerDate = new Date()
      setTime(`${padNumber(innerDate.getHours())}:${padNumber(innerDate.getMinutes())}`)
    }, 1000)

    return () => window.clearInterval(intervalId)
  }, [setTime])

  return (
    <div className={styles.wrap}>
      <div className={styles.text}>{ctime}</div>
    </div>
  )
}

export default Time
