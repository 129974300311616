import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetJson } from '../../util/api'

const initialState = {
  status: 'initial',
  name: null,
  slug: null,
  stops: null,
  index: 0
}

export const fetchRoute = createAsyncThunk(
  'route/fetchRoute',
  async ({ routeSlug }) => {
    const response = await apiGetJson(routeSlug)
    // The value we return becomes the `fulfilled` action payload
    return { route: response.data, routeSlug }
  }
)

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    nextStep: (state) => {
      state.index = Math.min(state.stops.length - 1, state.index + 0.5)
    },
    previousStep: (state) => {
      state.index = Math.max(0, state.index - 0.5)
    },
    resetIndex: (state) => {
      state.index = 0
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoute.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchRoute.fulfilled, (state, action) => {
        const stops = action.payload.route.stops

        if (stops[0].signal) {
          stops[0].signal = false
        }

        if (stops[stops.length - 1].signal) {
          stops[stops.length - 1].signal = false
        }

        state.status = 'loaded'
        state.stops = stops
        state.index = 0
        state.name = action.payload.route.name
        state.slug = action.payload.route.slug
      })
  }
})

export const {
  nextStep,
  previousStep,
  resetIndex
} = routeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRoute = (state) => state.route

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectRoute(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default routeSlice.reducer
