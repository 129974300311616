import React, { useEffect } from 'react'
import {
  RouterProvider,
  createRootRoute,
  createRoute,
  createRouter,
  Outlet,
  redirect
} from '@tanstack/react-router'
import Spinner from 'react-bootstrap/Spinner'
import t from 'i18n'
import { useSelector, useDispatch } from 'react-redux'

import Routes from './features/routes/Routes'
import Route from './features/route/Route'
import Directions from './features/route/Directions'
import Direction from './features/route/Direction'
import './App.sass'

import {
  fetchRoutes,
  selectRoutes
} from './features/routes/routesSlice'

const rootRoute = createRootRoute({ component: () => <Outlet /> })
const indexRoute = createRoute({ path: '/', getParentRoute: () => rootRoute, loader: () => { throw redirect({ to: '/type/buses' }) } })
const routesRoute = createRoute({ path: '/type/$routesType', getParentRoute: () => rootRoute, component: () => <Outlet /> })
const routesIndexRoute = createRoute({ path: '/', getParentRoute: () => routesRoute, component: Routes })
const routeRoute = createRoute({ path: '/$routeSlug', getParentRoute: () => routesRoute, component: Route })
const directionsRoute = createRoute({ path: '/', getParentRoute: () => routeRoute, component: Directions })
const directionRoute = createRoute({ path: '/$direction', getParentRoute: () => routeRoute, component: Direction })

const routeTree = rootRoute.addChildren([
  indexRoute,
  routesRoute.addChildren([
    routesIndexRoute,
    routeRoute.addChildren([
      directionsRoute,
      directionRoute
    ])
  ])
])

const router = createRouter({ routeTree, defaultPreload: 'intent' })

function App () {
  const routes = useSelector(selectRoutes)
  const dispatch = useDispatch()
  const isInitial = routes.status === 'initial'

  useEffect(() => {
    if (isInitial) {
      dispatch(fetchRoutes())
    }
  }, [dispatch, isInitial])

  useEffect(() => {
    window.speechSynthesis.getVoices()

    try { navigator.wakeLock.request('screen') } catch (_e) {}
  }, [])

  return (
    <div className='App'>
      {routes.status === 'loaded'
        ? (
          <RouterProvider router={router} />
          )
        : (
          <div className='d-flex align-items-center justify-content-center flex-grow-1 gap-3'>
            <Spinner />
            {t('loadingRoutes')}
          </div>
          )}
    </div>
  )
}

export default App
