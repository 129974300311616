import React from 'react'
import t from 'i18n'

import Nav from 'react-bootstrap/Nav'
import { Link } from '@tanstack/react-router'

const TYPES = [
  'buses',
  'trams',
  'trains',
  'metro',
  'boats',
  'cable-cars',
  'school'
]

export default function TypeNavigation ({ routes }) {
  return (
    <Nav variant='pills' defaultActiveKey={window.location.pathname} className='justify-content-center'>
      {TYPES.map((type) => (
        <Nav.Item key={type}>
          <Link to={`/type/${type}`} className='nav-link'>
            {t(`type/${type}`)}
          </Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}
