import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from '@tanstack/react-router'

import Icon from 'components/Icon'

import { selectRoute, resetIndex } from 'features/route/routeSlice'

import speak from 'util/voice'

import Controls from './Controls'
import Time from './Time'
import Stop from './Stop'
import styles from './Direction.module.sass'

const orderStops = ({ route, direction }) => {
  const index = Math.ceil(route.index)

  if (direction === 'backward') {
    return [...route.stops].reverse().slice(index, index + 5)
  } else {
    return route.stops.slice(index, index + 5)
  }
}

export function Direction () {
  const route = useSelector(selectRoute)
  const dispatch = useDispatch()

  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  const { direction, routesType, routeSlug } = useParams({ strict: true })

  const orderedStops = orderStops({ route, direction })

  useEffect(() => {
    dispatch(resetIndex())
  }, [direction])

  useEffect(() => {
    if (route.status === 'loaded') {
      speak(orderedStops[Math.ceil(route.index)].name)
    }
  }, [route.status === 'loaded'])

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)
    window.addEventListener('fullscreenchange', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
      window.removeEventListener('fullscreenchange', handleResize)
    }
  }, [setDimensions])

  let fontSize, maxWidth

  if (dimensions.width / dimensions.height < 2160 / 1620) {
    fontSize = dimensions.width / 2160 * 1620
  } else {
    fontSize = dimensions.height
    maxWidth = 2160 / 1620 * fontSize
  }

  let nameTransform

  if (route.name && route.name.length > 3) {
    const scale = Math.round(1000 * 3 / route.name.length) / 1000
    nameTransform = `scale(${scale})`
  }

  return (
    <div className={styles.wrap} style={{ fontSize }}>
      <div className={styles.box} style={{ height: fontSize }}>
        <header className={styles.header}>
          <h1 className={styles.nameWrap}>
            <Link to={`/type/${routesType}/${routeSlug}`} className={styles.nameLink}>
              <span className={styles.name} style={{ transform: nameTransform }}>{route.name}</span>
            </Link>
          </h1>

          {route.to
            ? (
              <div className={styles.to}>
                <Icon name='arrow-right' faded height={0.75} />

                <h2 className={styles.toText}>
                  {route.to}
                </h2>
              </div>
              )
            : null}
        </header>

        <Time />

        <div className={styles.stops}>
          <div className={styles.svgWrap}>
            <div className={styles.svgPositioner} style={{ maxWidth }}>
              <svg
                className={styles.svg}
                width='365'
                height='190'
                style={{ maxWidth }}
                viewBox='0 0 96.455 50.683'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.418 53.93s.483-42.957 96.313-49'
                  transform='translate(-8.5 -3.249)'
                  fill='none'
                  stroke='currentColor'
                />
              </svg>

              {orderedStops.map((stop) => (
                <Stop key={stop.sequence} stop={stop} />
              ))}
            </div>
          </div>

          <Controls route={route} dispatch={dispatch} />
        </div>
      </div>
    </div>
  )
}

export default Direction
