import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from '@tanstack/react-router'
import Table from 'react-bootstrap/Table'

import t from 'i18n'

import Icon from 'components/Icon'

import {
  selectRoute,
  resetIndex,
} from 'features/route/routeSlice'

import DirectionTile from './DirectionTile'
import styles from './Directions.module.sass'

export function Directions () {
  const dispatch = useDispatch()
  const route = useSelector(selectRoute)
  const reversedStops = [...route.stops].reverse()
  let runner = 0
  let backRunner = 0

  useEffect(() => { dispatch(resetIndex()) }, [dispatch])

  const { routesType, routeSlug } = useParams({ strict: true })
  const pathBase = `/type/${routesType}/${routeSlug}`

  return (
    <>
      <h1 className='m-0 text-center'>
        <Link to={`/type/${routesType}`}>
          {t('shotoush')}
        </Link>
      </h1>

      <p className='text-center m-0'>{t('selectRouteDirection')}</p>

      <DirectionTile
        route={route}
        pathBase={pathBase}
        direction='forward'
      />

      <DirectionTile
        route={route}
        pathBase={pathBase}
        direction='backward'
      />

      <hr />

      <Table bordered className={styles.table}>
        <tbody>
          {route.stops.map((stop, i) => {
            runner += stop.timer

            return (
              <tr key={stop.sequence}>
                <td className={styles.tdMinimal}>{runner}</td>
                <td className={styles.noBorderRight}>
                  {i === 0 ? <strong>{stop.name}</strong> : stop.name}
                </td>
                <td className={styles.tdForSignalIcon}>{stop.signal ? <Icon regularHeight name='bell' /> : null}</td>
                <td className={styles.tdMinimal}>{stop.zone}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <hr />

      <Table bordered className={styles.table}>
        <tbody>
          {reversedStops.map((stop, i) => {
            backRunner += i === 0 ? 0 : reversedStops[i - 1].timer

            return (
              <tr key={stop.sequence}>
                <td className={styles.tdMinimal}>{backRunner}</td>
                <td className={styles.noBorderRight}>
                  {i === 0 ? <strong>{stop.name}</strong> : stop.name}
                </td>
                <td className={styles.tdForSignalIcon}>{stop.signal ? <Icon regularHeight name='bell' /> : null}</td>
                <td className={styles.tdMinimal}>{stop.zone}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default Directions
