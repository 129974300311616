import React from 'react'
import { Link } from '@tanstack/react-router'

import styles from './RouteTile.module.sass'

export default function RouteTile ({ route, pathBase }) {
  return (
    <Link className={styles.wrap} to={`${pathBase}/${route.slug}`}>
      <h2 className={styles.name}>{route.name}</h2>

      <div className={styles.info}>
        <div className='text-truncate'>{route.from}</div>
        <div className='text-truncate pt-1'><span className={styles.arrow}>↳</span> {route.to}</div>
      </div>
    </Link>
  )
}
