import React from 'react'
import { Link } from '@tanstack/react-router'

import requestFullscreen from 'util/requestFullscreen'

import styles from './DirectionTile.module.sass'

export default function RouteTile ({ route, direction, pathBase }) {
  let from, to

  if (direction === 'forward') {
    from = route.stops[0]
    to = route.stops[route.stops.length - 1]
  } else {
    from = route.stops[route.stops.length - 1]
    to = route.stops[0]
  }

  return (
    <Link
      className={styles.wrap}
      to={`${pathBase}/${direction}`}
      onClick={requestFullscreen}
    >
      <h2 className='mb-3'>{route.name}</h2>

      <div className='text-truncate'>{from.name}</div>
      <div className='text-truncate'><span className={styles.arrow}>↳</span> {to.name}</div>
    </Link>
  )
}
